<template lang="html">


  <div class="usluga">
    <div class="headUsluga">


    <h1>KeySolutions Kandidati</h1>
    <p>Tražite posao?</p>
    <p>Popunite polja ispod, pošaljite nam Vašu radnu biografiju i na taj način postanite deo naše baze, a mi ćemo Vas kontaktirati ukoliko se otvori radno mesto koje odgovara Vašim kvalifikacijama.</p>
  </div>

    <div class="card-body">
       <form>
         <div class="form-group">
           <label for="name">Ime i Prezime</label>
           <input
             class="form-control"
             type="text"
             name="name"
             v-model="name"
             id="name"
             >
         </div>
         <div class="form-group">
           <label for="birthDate">Datum i godina rođenja</label>
           <input
             class="form-control"
             type="text"
             name="birthDate"
             v-model="birthDate"
             id="birthDate"
             >
         </div>
         <div class="form-group">
           <label for="residency">Mesto Prebivališta</label>
           <input
             class="form-control"
             type="text"
             name="residency"
             v-model="residency"
             id="residency"
             >
         </div>
         <div class="form-group">
           <label for="email">Vaša E-mail Adresa</label>
           <input
             class="form-control"
             type="text"
             name="email"
             v-model="email"
             id="email"
             >
         </div>
         <div class="form-group">
           <label for="degree">Stepen Stručne Spreme</label>
           <input
             class="form-control"
             type="text"
             name="degree"
             v-model="degree"
             id="degree"
             >
         </div>
         <div class="form-group">
           <label for="desiredWorkplace">Željena Industrija/branša za rad</label>
           <input
             class="form-control"
             type="text"
             name="desiredWorkplace"
             v-model="desiredWorkplace"
             id="desiredWorkplace"
             >
         </div>
         <!-- <div class="form-group">
           <label for="cv">Dodajte CV</label>&nbsp;&nbsp;&nbsp;&nbsp;
           <label for="cv">[[Implementacija file browser-a]]</label>
         </div> -->
        <button class="btn btn-primary" @click="changeBody()">Pošalji Formular</button>
        &nbsp;&nbsp;&nbsp;&nbsp;

      </form>

  </div>

  </div>

</template>

<script>
export default {

  name: 'KSKandidati',
  components: {

  },
  methods:{

  },
  beforeCreate: function() {
        document.body.className = 'kandidati';
  }

}
</script>

<style lang="css" scoped>

.usluga{
position: absolute;
top: auto;
left: 15%;
right: 15%;
background-color: white;
border-style: solid;
border-color: black;
border-width: medium;
border-radius: 15px;
margin-top: 15px;

}

.headUsluga{
  background-color: #77bbee;
  padding: 10px;
  border-top-left-radius:  10px;
  border-top-right-radius:  10px;
}
.card-body{
  background-color: #bbffff;
  border-bottom-left-radius:  10px;
  border-bottom-right-radius:  10px;

}

</style>
